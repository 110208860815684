import './App.css';
import { Button } from '@mui/material';
import OTP from "./OTP"
import React, { useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';

let operators = [{
  sign: "+",
  method: (a, b) => a + b
}, {
  sign: "-",
  method: (a, b) => a - b
}, {
  sign: "*",
  method: (a, b) => a * b
}, {
  sign: "/",
  method: (a, b) => a / b
}];

const number1 = Math.round(Math.random() * 1000000000000000)
const generateNumber2 = (sign) => Math.round(Math.random() * (sign == "*" || sign == "/" ? 9 : 1000000000000000))
const operationSign = operators[Math.floor(Math.random() * 4)]
const numbers2 = generateNumber2(operationSign.sign)


const Digits = React.memo(({ integers }) => {
  const int = integers
  const intStr = int.toString()
  const intLen = intStr.length

  const [value, setValue] = useState(intStr)

  return (
    <OTP separator={""} value={value} onChange={setValue} length={intLen} />
  )
})

const Formula = () => (
  <>
    <div className='formula'>
      <Digits integers={number1} />

      <h1> {operationSign.sign}</h1>

      <Digits integers={numbers2} />
    </div>
    <AnswerBlock />
  </>)



const AnswerBlock = () => {
  const [answerIsVisible, setAnswerIsVisible] = useState(false)
  const buttonText = answerIsVisible ? "hide answer" : "Show answer"
  return (
    <>
      {answerIsVisible && <div className='answer' > {operationSign.method(number1, numbers2)}</div >}
      <Button onClick={() => setAnswerIsVisible(!answerIsVisible)}>{buttonText}</Button>
    </>
  )
}


function App() {

  const handleReload = () => window.location.reload();

  return (
    <div className="App">
      <header className="App-header">
        <h2 className='logo'>BrainVsBrain</h2>
        <p className='descr'>Today&apos;s math challange</p>
      </header>

      <main className='main-content'>

        <Formula />
        <Button onClick={handleReload} startIcon={<CachedIcon />}>Change equation</Button>
      </main>

    </div>
  )
}


export default App;
